// @flow

import React from 'react';
import type { Node } from 'react';
import { useQuery } from 'shared_services/apollo/useQuery';
import { LegalsDialog } from 'shared_components/legals/Dialog';
import READ_LEGAL_QUERY from 'shared_data/queries/legal/read.graphql';

type Props = {
  type: string,
  skip?: boolean,
  onClose?: Function,
  children?: Function,
};

/**
 * ReadLegalQuery
 *
 * @param {Props} props
 */
export const ReadLegalQuery = ({
  children = null,
  type,
  skip = false,
  onClose = null,
}: Props): Node => {
  const {
    loading,
    error,
    data = {},
  } = useQuery(READ_LEGAL_QUERY, {
    skip,
    ssr: false,
    variables: { type },
  });

  if (error) {
    return null;
  }

  if (typeof children === 'function') {
    return children({ loading, data: data.readLegal });
  }

  return <LegalsDialog {...data.readLegal} onClose={onClose} loading={loading} isActive />;
};
