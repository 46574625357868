// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Modal } from '@riseart/antd-provider';
import { Markdown, Button } from '@riseart/common';
import { CloseIcon } from '@riseart/icons';
import { LegalsDialogTitle } from 'shared_components/legals/DialogTitle';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';

import { legalsDialogCls, legalsDialogLoadingCls } from 'shared_components/legals/Dialog.less';

type LegalsDialogProps = {
  isActive: boolean,
  effectiveDate: string,
  title: string,
  legalText: string,
  onClose: Function,
  loading: boolean,
};

export const LegalsDialog = ({
  isActive,
  effectiveDate,
  title,
  legalText,
  onClose,
  loading,
}: LegalsDialogProps) => (
  <Modal
    className={classNames(legalsDialogCls, { [legalsDialogLoadingCls]: loading })}
    title={title && <LegalsDialogTitle title={title} effectiveDate={effectiveDate} />}
    open={isActive}
    closeIcon={<CloseIcon />}
    onCancel={onClose}
    footer={
      onClose ? (
        <FormattedMessage id="forms.common.close">
          {(text: string) => (
            <Button type="primary" onClick={onClose}>
              {text}
            </Button>
          )}
        </FormattedMessage>
      ) : null
    }
  >
    {legalText ? <Markdown text={legalText} /> : <IsomorphicRipple isActive />}
  </Modal>
);
