// @flow

import React from 'react';
import { Heading } from '@riseart/common';
import { FormattedMessage } from 'react-intl';

type LegalsDialogTitleProps = {
  effectiveDate: string,
  title: string,
};

export const LegalsDialogTitle = ({ title, effectiveDate }: LegalsDialogTitleProps) => (
  <React.Fragment>
    <Heading tag="h3" level="3">
      {title}
    </Heading>
    <FormattedMessage
      id="components.auth.effective_date"
      values={{ date: new Date(effectiveDate).toDateString() }}
    />
  </React.Fragment>
);
